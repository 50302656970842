<template>
  <div class="grid">
    <Toolbar class="col-12 mt-2 no-print" v-if="loaded == 'loaded'">
      <template #end>
        <i class="mr-2" />
        <Button :label="$t('button.print')" icon="pi pi-print" @click="onPrint($event)" />
        <i class="mr-2" />
      </template>
    </Toolbar>
    <div class="col-6 mb-2" style="text-transform: uppercase">
      <h5 style="font-weight: bold; font-size: 18px">
        {{ $t("receipt.title_print") }}
      </h5>
    </div>
    <div class="col-6 receiptDate">
      <span>{{ $t("receipt.receiptDate") }}</span>
      <span>{{ receiptDate }}</span>
    </div>
  </div>
  <div class="grid-container">
    <div class="grid-item">
      <div class="brand">
        <div class="mb-3" style="flex-grow: 2">
          <img src="images/SaiGonTravel.png" class="logo" />
          <div class="address">{{ loginedUser.organization }}</div>
          <div class="address">{{ loginedUser.orgPhone }}</div>
        </div>
        <div style="margin: 0.5rem; flex-grow: 1">
          <qrcode-vue :value="qrcodeReceipt" :size="120" level="H" />
        </div>
      </div>
      <div className="address mb-2">{{ loginedUser.orgAddr }}</div>
    </div>
    <div class="grid-item">
      <!-- <div class="header">
        <label>{{ $t("receipt.receiptNumber") }}</label>
        <label>{{ $t("receipt.pieces") }}</label>
      </div> -->
      <div style="display: flex; height: 100%">
        <div style="flex-grow: 5; border: 1px solid #ced4da">
          <div className="header">
            <label>{{ $t("receipt.receiptNumber") }}</label>
          </div>
          <div className="tracking">
            {{ receipt.receiptCode }}
          </div>
        </div>
        <div style="flex-grow: 5; border-left: 1px solid #ced4da">
          <div className="header">
            <label>{{ $t("receipt.pieces") }}</label>
          </div>
          <div className="tracking">
            {{ receipt.packageNumber }}
          </div>
        </div>
      </div>
    </div>
    <div class="grid-item">
      <div class="grid" style="margin: 0">
        <div class="col-12 header">
          <label>{{ $t("receipt.title_sender") }}</label>
        </div>
        <label for="sendername" class="col-4">{{
          $t("receipt.full_name_usvn")
          }}</label>
        <div id="sendername" class="col-8 content">
          {{ receipt.senderName }}
        </div>
        <label for="sender_address" class="col-4">{{
          $t("receipt.address_usvn")
          }}</label>
        <div id="sender_address" class="col-8 content">{{ senderAddress }}</div>
        <label for="senderphone" class="col-4">{{
          $t("receipt.phone_usvn")
          }}</label>
        <div id="senderphone" class="col-8 content">
          {{ receipt.senderPhone }}
        </div>
        <div class="col-12 header">
          <label>{{ $t("receipt.title_recipient") }}</label>
        </div>
        <label for="receivername" class="col-4 mb-2">{{
          $t("receipt.full_name_usvn")
          }}</label>
        <div class="col-8 content">{{ receipt.recipientName }}</div>
        <label for="sender_address" class="col-4">{{
          $t("receipt.address_usvn")
          }}</label>
        <div id="sender_address" class="col-8 content">
          {{ recipientAddress }}
        </div>

        <label for="recipientPhone" class="col-4">{{
          $t("receipt.phone_usvn")
          }}</label>
        <div id="recipientPhone" class="col-8 content">
          {{ receipt.recipientPhone }}
        </div>
      </div>
    </div>
    <div class="grid-item">
      <div class="grid" style="margin-left: 0; margin-top: 6px">
        <label for="service" class="col-6">{{ $t("receipt.service") }}</label>
        <div class="col-6 content">
          {{ formatDelivery(receipt.deliveryType) }}
        </div>
        <!--
        <label for="numofpackage" class="col-6">{{$t('receipt.number_of_packages')}}</label>
        <div class="col-6">{{receipt.packageNumber}}</div>
        -->
        <label for="weight" class="col-6">{{
          $t("receipt.weight_usvn")
          }}</label>
        <div class="col-6 content">
          <label id="declared_weight">{{ receipt.weight }} {{ $t("unit.lbs") }} / {{ weightKg }}
            {{ $t("unit.kg") }}</label>
        </div>

        <label for="unitprice" class="col-6">{{
          $t("receipt.unit_price_usvn")
          }}</label>
        <div for="unitprice" class="col-6 content">
          ${{ receipt.unitPrice }}/Lbs
        </div>

        <label for="extra_tax" class="col-6">{{
          $t("receipt.extra_fee_usvn")
          }}</label>
        <div class="col-6 content">${{ receipt.extraTax || 0 }}</div>

        <label for="extra_tax" class="col-6">{{
          $t("receipt.over_size_usvn")
          }}</label>
        <div class="col-6 content">${{ receipt.overSizeFee || 0 }}</div>
        <label for="remoteFee" class="col-6">{{
          $t("receipt.remote_fee_usvn")
          }}</label>
        <div id="remoteFee" class="col-6 content">
          ${{ receipt.remoteFee || 0 }}
        </div>
        <label for="insurance" class="col-6">{{
          $t("receipt.insurance")
          }}</label>
        <div class="col-6 content">${{ receipt.insuranceFee || 0 }}</div>
        <label for="extraFee" class="col-6">{{
          $t("receipt.extra_fee")
        }}</label>
        <div class="col-6 content">${{ receipt.extraFee || 0 }}</div>
        <label for="total" class="col-6" style="font-weight: bold; font-size: 1.5rem">{{ $t("receipt.total") }}</label>
        <div id="total" class="col-6 content" style="font-size: 1.5rem; color: red">
          ${{ receipt.totalFee }}
        </div>

        <label for="payment_status" class="col-6">{{
          $t("receipt.paymentStatus")
          }}</label>
        <div id="payment_status" class="col-6 content">
          {{ receipt.paymentStatus }}
          {{ receipt.paymentMethod ? ` / ${receipt.paymentMethod}` : null }}
        </div>
        <label for="allow_delivery" class="col-6">{{
          $t("receipt.allowDelivery")
          }}</label>
        <div id="allow_delivery" class="col-6 content">
          {{ receipt.allowDelivery == "1" ? "Yes" : "No" }}
        </div>
        <label for="delivery_note" class="col-6">{{
          $t("receipt.delivery_note")
          }}</label>
        <div id="delivery_note" class="col-6 content">
          {{ receipt.deliveryNote }}
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 header">
    <label>{{ $t("receipt.title_description") }}</label>
  </div>
  <div class="grid-container">
    <div class="grid-item items">
      <label style="width: 100%" v-for="(item, index) in leftItems" :key="index">{{ item }}</label>
    </div>
    <div class="grid-item items">
      <label style="width: 100%" v-for="(item, index) in rightItems" :key="index">{{ item }}</label>
    </div>
    <div class="grid-item signature">
      <label style="margin-top: 5px">{{
        $t("receipt.signature_sender")
        }}</label>
      <div class="content" style="margin-bottom: 3px">
        {{ receipt.senderName }}
      </div>
    </div>
    <div class="grid-item signature">
      <label style="margin-top: 5px">{{ $t("receipt.signature_agent") }}</label>
      <div class="content" style="margin-bottom: 3px">
        {{ loginedUser.userFullName }}
      </div>
    </div>
    <!--
    <div class= "deliverynote">
      <label for="delivery_note" class="col-2">{{$t('receipt.delivery_note')}}</label>
      <div class="col-10">{{receipt.deliveryNote}}</div>
    </div>
    -->
    <div class="footer">
      <label style="width: 100%">{{ $t("receipt.term_note") }}</label>
      <label style="width: 100%">{{ $t("receipt.term_note_vn") }}</label>
    </div>
    <div id="__loaded__" style="display: none">{{ loaded }}</div>
  </div>
</template>
<script>
import ReceiptService from "@/service/ReceiptService";
import Consts from "@/utils/consts";
import { DateTime } from "luxon";
import AuthService from "@/service/AuthService";
import Utils from "@/service/Utils";
export default {
  data() {
    return {
      receipt: {},
      loaded: "",
    };
  },
  computed: {
    senderAddress() {
      return ReceiptService.getSenderAddress(this.receipt);
    },
    recipientAddress() {
      return ReceiptService.getRecipientAddress(this.receipt);
    },
    qrcodeReceipt() {
      let resource = "receipt|" + this.receipt.receiptCode;
      resource = process.env.VUE_APP_BASE_URL + "#/" + Utils.toBase64(resource);
      return resource;
    },
    weightKg() {
      if (this.receipt.weight) {
        return Math.round(this.receipt.weight * Consts.POUND2KG * 100) / 100;
      }
      return 0;
    },
    receiptDate() {
      if (this.receipt && this.receipt.createdTimestamp) {
        const dateTime = DateTime.fromMillis(this.receipt.createdTimestamp);
        return dateTime.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
      } else {
        return "";
      }
    },
    loginedUser() {
      const user = AuthService.currentUser || {};
      return user;
    },
    leftItems() {
      let items = [];
      if (Array.isArray(this.receipt.packageItemDtos)) {
        for (
          let i = 0;
          i < this.receipt.packageItemDtos.length &&
          i < Consts.MAX_RECEIPT_ITEM_ROWS;
          i++
        ) {
          const pkgItem = this.receipt.packageItemDtos[i];
          items.push(pkgItem.quantity + " " + pkgItem.name);
        }
      }
      return items;
    },
    rightItems() {
      let items = [];
      if (Array.isArray(this.receipt.packageItemDtos)) {
        if (
          this.receipt.packageItemDtos.length > Consts.MAX_RECEIPT_ITEM_ROWS
        ) {
          for (
            let i = Consts.MAX_RECEIPT_ITEM_ROWS;
            i < this.receipt.packageItemDtos.length;
            i++
          ) {
            const pkgItem = this.receipt.packageItemDtos[i];
            items.push(pkgItem.quantity + " " + pkgItem.name);
          }
        } else if (
          this.receipt.packageItemDtos.length >
          2 * Consts.MAX_RECEIPT_ITEM_ROWS
        ) {
          items = [items.join(", ")];
        }
      }
      return items;
    },
  },
  methods: {
    formatDelivery(code) {
      switch (code) {
        case 1:
        case "1":
          return this.$t("receipt.serviceOptions.door2door");
        case 2:
        case "2":
          return this.$t("receipt.serviceOptions.pickatoffice");
        default:
          return "";
      }
    },
    onPrint() {
      window.self.print();
    },
  },
  mounted() {
    if (this.$route.params.id) {
      ReceiptService.get(this.$route.params.id).then((data) => {
        if (data) {
          this.receipt = data;
          this.loaded = "loaded";
          /*
          let packageItemDtos = this.receipt.packageItemDtos;
          for(let i = 0; i < 5; i++) {
            packageItemDtos = packageItemDtos.concat(this.receipt.packageItemDtos);
          }
          this.receipt.packageItemDtos = packageItemDtos;
          */
          //this.leftItems = this.receipt.packageItemDtos.map(item => item.name);
          //this.weightKg = Math.round(data.weight * Consts.POUND2KG * 100)/100
        }
      });
    }
  },
};
</script>
<style type="text/css">
@page {
  size: A4;
  margin-left: 5mm;
  margin-right: 5mm;
  margin-top: 5mm;
}
.grid-container {
  display: grid;
  grid-template-columns: 50% 50%;
}
.grid-item {
  border: 1px solid #ced4da;
}
.col-6 {
  flex: 0 0 auto;
  padding: 0.3rem;
  width: 50%;
}
.logo {
  width: 200px;
  padding: 1rem;
}
.address {
  margin-left: 0.5rem;
  font-size: 16px;
}
.brand {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.receiptDate {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;
}
.header {
  background-color: #c8c8c8;
  width: 100%;
  font-weight: bold;
  font-size: 18px;
  padding: 0.5rem;
}
.content {
  font-weight: bold;
}
.container {
  width: 210mm;
  height: 297mm;
  margin-left: 5mm;
  margin-right: 5mm;
  margin-top: 5mm;
}
.tracking {
  align-self: center;
  width: 100%;
  margin-left: 0rem;
  margin-top: 1.5rem;
  margin-right: 0px;
  margin-bottom: 0px;
  text-align: center;
  color: red;
  font-size: 2.5rem;
}
.items {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 360px;
  margin-right: 0rem;
  margin-left: 0rem;
  margin-top: 0rem;
  border: 1px solid #ced4da;
  padding: 0.5rem;
}
.signature {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 120px;
  text-align: center;
}
.deliverynote {
  display: flex;
  border: 1px solid #ced4da;
  width: 210mm;
  height: 11mm;
}
.footer {
  display: flex;
  flex-direction: column;
  text-align: center;
  position: absolute;
  top: 292mm;
  width: 210mm;
  height: 12mm;
}
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
  .header {
    background-color: #c8c8c8;
    width: 100%;
    font-weight: bold;
    font-size: 18px;
    padding: 0.5rem;
  }
  .items {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 400px;
    margin-right: 0rem;
    margin-left: 0rem;
    margin-top: 0rem;
    border: 1px solid #ced4da;
    padding: 0.5rem;
  }
  .footer {
    display: flex;
    flex-direction: column;
    text-align: center;
    position: absolute;
    top: 292mm;
    width: 210mm;
    height: 12mm;
    page-break-after: always;
  }
}
</style>
